import { ChangeDetectionStrategy, Component, computed, input, output, signal } from '@angular/core';
import { IGenericStatsItemInfo, IGenericStatsList, IGenericStatsResult, IStatsHeader } from '@vierkant-software/types__api';
import { StatsFormatType } from '@vierkant-software/types__api';
import { TableLazyLoadEvent } from 'primeng/table';
import { Globals } from 'src/app/services/globals.service';

interface FEIStatsHeader extends IStatsHeader {
  index: number;
}

@Component({
  selector:        'gc-generic-table',
  templateUrl:     './gc-generic-table.component.haml',
  styleUrls:       ['./gc-generic-table.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GcGenericTableComponent {
  genericStats = input<IGenericStatsResult | IGenericStatsList>();
  displayButton = input<boolean>(false);
  detailDialog = input<boolean>(false);
  currentDetailUser = signal<string>(undefined);
  enums = input(undefined);
  dictionaries = input(undefined);
  actions = input(undefined);
  freezeActions = input(false);
  selectedMainColumns = signal<FEIStatsHeader[]>([]);
  records: unknown;
  slectedSubColumns = signal<FEIStatsHeader[]>([]);
  selectedData = signal<unknown[]>([]); //IGenericStatsData

  //FIXME once we got angular 18.1
  pagination = computed(() => {
    const tmp = this.genericStats();
    if ('pagination' in tmp)
      return tmp.pagination;
  });
  // contextMenuItems = computed(() => Object.values(this.actions()).map(x => Object.values(x)).flat(1));
  confirmationText = input<string>(undefined);

  //TODO document it -> how to do callback with backend call
  rowClick = output<{element: IGenericStatsItemInfo, event: MouseEvent, index: number}>();
  pageChange = output<unknown>();

  constructor(public globals: Globals) { }

  asFormat(format: StatsFormatType) {
    const dt = Globals.DateTime;
    return Globals.DateTime[format as keyof typeof dt];
  }

  getOptions(data: string[], recordIndex: string) {
    const records = this.genericStats().header.records[recordIndex];
    const defs = this.dictionaries()[recordIndex];
    let res = data.slice(0, (defs.display)).map(element => defs.format(records[element])).join(', ');

    if (data.length > defs.display)
      res += ' und ' + (data.length - defs.display) + ' weitere';

    return res;
  }

  ngOnChanges() {
    this.selectedMainColumns.set(this.genericStats().header.headers.map((x,i) => ({...x, index: i})));
    this.selectedData.set(this.genericStats().stats);
    this.records = this.genericStats().header.records;
  }

  rowClicked(element: IGenericStatsItemInfo, event: MouseEvent, index: number) {
    this.rowClick.emit({element, event, index});
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  download(content: any, event: any) {
    event.stopPropagation();
    this.actions()[content.type][content.format].command(content.data[content.index]);
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  delete(content: any, event: any) {
    event.stopPropagation();
    this.actions()[content.type][content.format].command(content.data[content.index]);
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onLazyLoad(event: TableLazyLoadEvent){
    const page = Math.floor(event.first / event.rows);
    this.pageChange.emit({page: page, size: event.rows});
  }

}
