/* eslint-disable max-len */
import { Component, Input } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

type FaPrefix = "fas" | "gc";
type Mode = 'prime' | 'fa' | 'img';
export type Color = 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'text' | 'info' | 'text-inverse';

/**
 * Component mostly used for adding icons. By default it uses the free fontawesome icons.
 * It's possible to use PrimeNG icons or add your own image as an icon.
 * It's preferred to use the fontawesome icons.
 * 
 * @name Icon
 */
@Component({
    selector:    'gc-icon',
    templateUrl: './gc-icon.component.haml',
})
export class GcIconComponent {
    /**
     * Accepts the name of the icon. Icon names can be found in the following links below.
     * @link https://fontawesome.com/icons
     * @link https://primeng.org/icons#list
     * @example Font-Awesome
     * ```
     *  %gc-icon{icon: "pen", mode: "fa"}
     * ```
     * @example Prime
     * ```
     *  %gc-icon{icon: "pi-home", mode: "prime"}
     * ```
     */
    @Input() icon: string;
    /**
     * Mode is the source. 
     * If you want to add an icon from fontawesome, you don't need to add this param.
     * @values 'prime', 'fa', 'img'
     * @default "fa"
     * @remark 'prime' means that it comes from primeng, 'fa' means that it comes from fontawesome, 'img' means that you can imbed your own image as an icon
     */
    @Input() mode: Mode = 'fa';
    /**
     * "fas" stands for fa-solid, it's used when the mode is "fa".
     * "gc" used when importing our own icons.
     * @values 'fas' | 'gc'
     * @default "fas"
     * @example "gc"
     * ```
     *  %gc-icon{prefix: "gc", icon: "no-trash"}
     * ```
     * @remark In order to use your own custom icon with the prefix "gc", you need to create a file in src/icons directory and then add it in gcIconPack.ts
     * @example custom NoTrashIcon in src/icons
     * ```ts
     *  export const NoTrashIcon: FAIcon = {
     *      prefix:   "gc",
     *      iconName: "no-trash",
     *      icon:     [
     *          16, 16,
     *          [],
     *          null,
     *          "M1.70709 15.7071L15.7071 1.70711C16.0976 1.31658 16.0976 0.683417 15.7071 0.292893C15.3166 -0.0976311 14.6834 -0.0976311 14.2929 0.292893L0.292878 14.2929C-0.0976463 14.6834 -0.0976463 15.3166 0.292878 15.7071C0.683402 16.0976 1.31657 16.0976 1.70709 15.7071ZM10.5714 1H12.9357L10.9165 3H2.85713C2.38302 3 1.99998 2.55312 1.99998 2C1.99998 1.44687 2.38302 1 2.85713 1H5.42856L5.62141 0.553125C5.76606 0.2125 6.06338 1.49012e-08 6.38749 1.49012e-08H9.61248C9.93659 1.49012e-08 10.2339 0.2125 10.3786 0.553125L10.5714 1ZM2.85713 4H9.90689L7.85974 6.02766C7.75862 6.06883 7.67445 6.15316 7.62366 6.26149L5.85713 8.0112V6.5C5.85713 6.225 5.66427 6 5.42856 6C5.19284 6 4.99998 6.225 4.99998 6.5V8.86018L2.85713 10.9826V4ZM7.57141 13.5V10.5357L5.85713 12.2337V13.5C5.85713 13.775 5.66427 14 5.42856 14C5.19284 14 4.99998 13.775 4.99998 13.5V13.0827L3.07958 14.9848C3.37444 15.5906 3.93258 16 4.57141 16H11.4286C12.3741 16 13.1428 15.1031 13.1428 14V5.01736L11 7.1398V13.5C11 13.775 10.8071 14 10.5714 14C10.3357 14 10.1428 13.775 10.1428 13.5V7.98878L8.42856 9.68674V13.5C8.42856 13.775 8.2357 14 7.99998 14C7.76427 14 7.57141 13.775 7.57141 13.5Z"
     *      ]
     *  };
     * ```
     * @example gcIconPack.ts
     * ```ts
     *  export const GcIconPack: IconPack = {
     *      noTrash:   NoTrashIcon,
     *  } as IconPack;
     * ```
     */
    @Input() prefix: FaPrefix = "fas";
    /**
     * Adds color to the icon
     * 
     * @values 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'text' | 'info' | 'text-inverse'
     */
    @Input() color?: Color;
    //for future purposes
    /**
     * Adds a background color to the icon.
     * 
     * @values 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'text' | 'info' | 'text-inverse'
     */
    @Input() backgroundColor?: Color;

    get _primeIcon(){
        return this.icon as string;
    }
    get _faIcon(){
        return [this.prefix, this.icon] as IconProp;
    }

    get _img() {
        return this.icon as string;
    }

    getColor(color: Color){
        if (!color) return undefined;
        if (color === 'primary') return 'var(--gc-color-primary)';
        if (color === 'secondary') return 'var(--gc-color-secondary)';
        if (color === 'success') return 'var(--gc-color-success)';
        if (color === 'warning') return 'var(--gc-color-warning)';
        if (color === 'danger') return 'var(--gc-color-danger)';
        if (color === 'text') return 'var(--gc-text-color)';
        if (color === 'text-inverse') return 'var(--gc-text-color-inverse)';
        if (color === 'info') return 'var(--gc-color-info)';
        return 'transparent';
    }
}
